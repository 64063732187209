<template>
  <div class="price-index-days">
    <el-button class="price-index-day" icon="el-icon-circle-plus" type="text" @click="$emit('input', null)">
      {{ $t('add') }}
    </el-button>
    <div
      v-for="day of indexPriceLists"
      :key="day._id"
      class="price-index-day"
      :class="{ active: modelId === day._id }"
      @click="$emit('input', day)"
    >
      {{ day.date | day }}
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { IndexPriceLists } from '@/imports/api/collections';

export default {
  name: 'PriceIndexDays',
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
  props: {
    priceIndexId: String,
    date: {
      default: () => new Date(),
    },
    value: Object,
  },
  computed: {
    modelId() {
      return this.value && this.value._id;
    },
  },
  meteor: {
    $subscribe: {
      'indexPriceLists.dateRange'() {
        const startOfMonth = moment.utc(this.date).startOf('month');
        return [this.priceIndexId, startOfMonth.toDate(), startOfMonth.add(1, 'month').toDate()];
      },
    },
    indexPriceLists() {
      return IndexPriceLists.find({}, { sort: { date: 1 } });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.price-index-days {
  background-color: #fff;
  border: 1px solid $outline;
}

.price-index-day {
  width: 100%;
  cursor: pointer;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 1px solid $outline;
  transition: border-color 100ms ease-in-out;
  outline: none;

  &:hover {
    background-color: $lighter-gray;
  }

  &.active {
    background-color: $lighter-gray;
    border-left: 2px solid $primary;

    @at-root [dir='rtl'] #{&} {
      border-left: 2px solid transparent;
      border-right: 2px solid $primary;
    }
  }
}
</style>
