<template>
  <div class="card shadow edit-price-list">
    <div class="card-header header">
      {{ $t(cardTitle) }}
      <div class="actions">
        <UploadFileButton @upload="file = $event" />
        <el-button v-if="file" icon="el-icon-back" type="danger" @click="file = null" />
        <el-button v-else icon="el-icon-check" type="primary" :loading="submitting" @click="submit" />
      </div>
    </div>
    <div class="card-body">
      <PriceListBulkImport v-if="file" :price-index="priceIndex" :file="file" @done="file = null" />
      <template v-else>
        <el-form>
          <el-form-item>
            <el-date-picker v-model="priceList.date" type="date" format="dd/MM/yyyy" :clearable="false" />
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="priceList.noTrade">{{ $t('no trade') }}</el-checkbox>
          </el-form-item>
        </el-form>
        <PriceListTable :price-index="priceIndex" :price-list="priceList" />
      </template>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from 'vue';
import moment from 'moment';
import { insert, update } from '@/modules/price-indexes/compositions/price-list-operations';
import { useSubmitting } from '@/modules/core/compositions/submitting';
import PriceListTable from '@/modules/price-indexes/components/PriceListTable';
import UploadFileButton from '@/modules/core/components/UploadFileButton';
import PriceListBulkImport from '@/modules/price-indexes/components/PriceListBulkImport';

export default {
  name: 'EditPriceList',
  components: { PriceListBulkImport, UploadFileButton, PriceListTable },
  props: {
    priceIndex: {
      type: Object,
      default: () => ({}),
    },
    value: Object,
  },
  setup(props) {
    const file = ref(null);
    const isEditMode = computed(() => !file.value && props.value && !!props.value._id);
    const priceList = ref(getClonedPriceList());

    function getClonedPriceList() {
      const obj = JSON.parse(
        JSON.stringify(
          props.value || {
            date: new Date(),
            noTrade: false,
            priceIndexId: props.priceIndex._id,
            prices: [],
          }
        )
      );
      obj.date = new Date(obj.date);
      return obj;
    }

    watch(
      () => props.value,
      () => {
        priceList.value = getClonedPriceList();
        file.value = null;
      },
      { immediate: true }
    );

    return {
      cardTitle: computed(() => {
        if (file.value) {
          return 'upload price lists from file';
        }
        return isEditMode.value ? 'edit price list' : 'add price list';
      }),
      file,
      priceList,
      ...useSubmitting(
        async () => {
          const { _id, date, prices, noTrade, priceIndexId } = priceList.value;
          const utcDate = moment.utc(moment(date).format('YYYY-MM-DD')).startOf('day').toDate();

          if (isEditMode.value) {
            await update({
              _id,
              modifier: {
                $set: { date: utcDate, prices, priceIndexId, noTrade },
              },
            }).catch(() => Promise.reject(new Error('failed to update price list')));
          } else {
            await insert({ date: utcDate, prices, priceIndexId, noTrade }).catch(() =>
              Promise.reject(new Error('failed to insert price list'))
            );
          }
        },
        {
          success: { title: '' },
        }
      ),
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.edit-price-list {
  flex: 1;
  margin: 0 5px;
}

.header {
  height: unset;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.actions {
  display: flex;
  flex-direction: row;
}
</style>
