import moment from 'moment';

export function getImportedEntity(entity, importTemplate) {
  const itemName = entity[importTemplate.itemNameHeader] || '';
  const selectedPrice = Math.round(entity[importTemplate.selectedHeader] * 100) || undefined;
  const typeAPrice = Math.round(entity[importTemplate.typeAHeader] * 100) || undefined;
  const date = moment.utc(entity[importTemplate.dateHeader], importTemplate.dateFormat);
  const formattedDate = date.format('YYYY-MM-DD');

  return {
    itemName: itemName.trim(),
    selectedPrice,
    typeAPrice,
    date,
    formattedDate,
  };
}
