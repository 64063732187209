<template>
  <el-table :data="priceList.prices">
    <el-table-column :label="importTemplate.itemNameHeader" :formatter="getItemName" />
    <el-table-column :label="importTemplate.selectedHeader" prop="selectedPrice" :formatter="moneyFormatter" />
    <el-table-column :label="importTemplate.typeAHeader" prop="typeAPrice" :formatter="moneyFormatter" />
  </el-table>
</template>

<script>
import { computed } from 'vue';
import accounting from 'accounting';

export default {
  name: 'PriceListTable',
  props: {
    priceList: Object,
    priceIndex: Object,
  },
  setup(props) {
    const itemsMap = computed(() =>
      props.priceIndex.items.reduce((map, item) => {
        map[item._id] = item.name;
        return map;
      }, {})
    );

    return {
      getItemName: (item) => itemsMap.value[item.itemId],
      importTemplate: computed(() => props.priceIndex.importTemplate || {}),
      moneyFormatter: (item, { property }) => {
        return item[property] ? accounting.formatMoney(item[property] / 100) : '-';
      },
    };
  },
};
</script>
