<template>
  <div>
    <div v-if="bulkImport.missingItems.length">
      <div class="import-items-title">
        <span>{{ $t('missing the following items') }}:</span>
        <el-button icon="el-icon-plus" size="mini" :loading="loadingMissingItems" @click="addMissingItems">
          {{ $t('add all') }}
        </el-button>
      </div>
      <div class="import-items-block">
        <span v-for="item in bulkImport.missingItems" :key="item" class="import-item">{{ item }}</span>
      </div>
    </div>

    <div v-if="bulkImport.priceLists.length">
      <div class="import-items-title">
        <span>{{ $t('import dates above') }}:</span>
        <el-button
          icon="el-icon-plus"
          size="mini"
          :loading="loadingImportedPriceLists"
          @click="submitImportedPriceLists"
        >
          {{ $t('add all') }}
        </el-button>
      </div>
      <div class="import-items-block">
        <span v-for="(item, index) in bulkImport.priceLists" :key="index" class="import-item">{{
          item.date | displayDate
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { watch } from 'vue';

import { useImportPriceList } from '@/modules/price-indexes/compositions/import-price-list';

export default {
  name: 'PriceListBulkImport',
  filters: {
    displayDate(value) {
      return value.format('DD/MM/YYYY');
    },
  },
  props: {
    file: File,
    priceIndex: Object,
  },
  setup(props) {
    const result = useImportPriceList(props);
    watch(
      () => props.file,
      (file) => {
        return file && result.insertListFromFile(file);
      },
      { immediate: true }
    );

    return result;
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.import-items-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.import-items-block {
  margin-top: 10px;
  border: 1px solid $outline;
  padding: 5px;
}

.import-item {
  background-color: $primary;
  color: #fff;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  display: inline-block;
}
</style>
