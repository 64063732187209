<template>
  <page-layout>
    <template slot="breadcrumb">
      <el-breadcrumb-item>{{ $t('price indexes') }}</el-breadcrumb-item>
    </template>
    <div class="card shadow">
      <div class="card-body">
        <el-button size="small" @click="openEditModal()">{{ $t('add') }}</el-button>
        <el-table :data="priceIndexes" @row-click="navigateItem">
          <el-table-column :label="$t('name')" prop="name" />
          <el-table-column :label="$t('# items')" prop="items.length" />
          <el-table-column :label="$t('edit')">
            <template slot-scope="{ row }">
              <el-button type="text" icon="el-icon-edit-outline" @click.native.stop="openEditModal(row)" />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <EditPriceIndexModal v-if="editModal.open" :price-index="editModal.item" @close="closeEditModal" />
  </page-layout>
</template>

<script>
import { getCurrentInstance } from 'vue';
import { PriceIndexes } from '@/imports/api/collections';
import { useEditModal } from '@/modules/core/compositions/edit-modal';
import EditPriceIndexModal from './components/EditPriceIndexModal';

export default {
  name: 'PriceIndexes',
  components: { EditPriceIndexModal },
  setup() {
    const { $router } = getCurrentInstance().proxy;
    return {
      ...useEditModal(),
      navigateItem: (item) => {
        const value = item['_id'];
        return $router.push({
          name: 'clarity.priceIndex',
          params: {
            ['priceIndexId']: value,
          },
        });
      },
    };
  },
  meteor: {
    $subscribe: {
      'priceIndexes.all': [],
    },
    priceIndexes() {
      return PriceIndexes.find({});
    },
  },
};
</script>

<style scoped></style>
