<template>
  <page-layout :ready="$subReady['priceIndex.single']">
    <template v-if="priceIndex" slot="breadcrumb">
      <el-breadcrumb-item :to="{ name: 'clarity.priceIndexes' }">{{ $t('price indexes') }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ priceIndex.name }}</el-breadcrumb-item>
    </template>

    <div class="content">
      <div class="days">
        <el-date-picker v-model="date" class="days-picker" type="month"></el-date-picker>
        <PriceIndexDays v-model="selectedPriceList" :price-index-id="priceIndexId" :date="date" />
      </div>
      <EditPriceList v-if="priceIndex" :price-index="priceIndex" :value="selectedPriceList" />
    </div>
  </page-layout>
</template>
<script>
import moment from 'moment';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router/composables';

import { PriceIndexes } from '@/imports/api/collections';
import PriceIndexDays from '@/modules/price-indexes/components/PriceIndexDays';
import EditPriceList from '@/modules/price-indexes/components/EditPriceList';

export default {
  name: 'PriceIndex',
  components: { EditPriceList, PriceIndexDays },
  setup() {
    const {
      params: { priceIndexId },
      query: { month: monthQuery = moment.utc(THIS_MONTH).format('YYYY-MM') },
    } = useRoute();
    const THIS_MONTH = moment.utc().set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();

    const date = computed({
      get: () => (monthQuery.value ? moment.utc(monthQuery.value).toDate() : THIS_MONTH),
      set: (date) => {
        monthQuery.value = moment(date || THIS_MONTH).format('YYYY-MM');
      },
    });

    return {
      priceIndexId,
      date,
      selectedPriceList: ref(null),
    };
  },
  meteor: {
    $subscribe: {
      'priceIndexes.all': [],
      priceIndex() {
        return [this.priceIndexId];
      },
    },
    priceIndex() {
      return PriceIndexes.findOne({ _id: this.priceIndexId });
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 10px 0;
}

.days {
  display: flex;
  flex-direction: column;

  .days-picker {
    margin-bottom: 10px;
  }
}
</style>
