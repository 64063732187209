import { reactive } from 'vue';
import { readXlsxFile } from '@/modules/core/utils/xlsx-reader';
import { getImportedEntity } from '@/modules/price-indexes/utils/imported-entity';
import { useSubmitting } from '@/modules/core/compositions/submitting';
import { update as updatePriceIndex } from '@/modules/price-indexes/compositions/price-indexes-operations';
import { insert as insertPriceList } from '@/modules/price-indexes/compositions/price-list-operations';
import { getRandomId } from '@/modules/core/utils/meteor-item-id';

export function useImportPriceList(props) {
  const bulkImport = reactive({
    isActive: false,
    missingItems: [],
    priceLists: [],
  });
  const missingItemsForm = useSubmitting(async function () {
    const items = [...props.priceIndex.items, ...bulkImport.missingItems.map((name) => ({ _id: getRandomId(), name }))];
    await updatePriceIndex({ _id: props.priceIndex._id, modifier: { $set: { items } } });
    bulkImport.missingItems = [];
  });

  const priceListsForm = useSubmitting(async function () {
    const items = props.priceIndex.items || [];
    await Promise.all(
      bulkImport.priceLists.map(({ date, prices, missing }) => {
        const notMissing = missing
          .map(({ itemName, selectedPrice, typeAPrice }) => {
            const indexItem = items.find((i) => i.name === itemName);
            if (indexItem) {
              return {
                itemId: indexItem._id,
                selectedPrice,
                typeAPrice,
              };
            }
          })
          .filter(Boolean);

        const priceList = {
          date: date.toDate(),
          noTrade: false,
          priceIndexId: props.priceIndex._id,
          prices: [...prices, ...notMissing],
        };

        return insertPriceList(priceList);
      })
    );
    bulkImport.priceLists = [];
    bulkImport.isActive = false;
  });

  async function insertListFromFile(file) {
    bulkImport.isActive = true;
    bulkImport.missingItems = [];
    bulkImport.priceLists = [];
    const entries = await readXlsxFile(file);
    const { importTemplate, items } = props.priceIndex;
    const missingItems = {};
    const pricesByDates = {};

    entries.forEach((e) => {
      const { itemName, selectedPrice, typeAPrice, date, formattedDate } = getImportedEntity(e, importTemplate);
      if (!selectedPrice && !typeAPrice) return;

      if (!pricesByDates[formattedDate]) {
        pricesByDates[formattedDate] = { date, prices: [], missing: [] };
      }
      const indexItem = items.find((i) => i.name === itemName);
      if (indexItem) {
        pricesByDates[formattedDate].prices.push({
          itemId: indexItem._id,
          selectedPrice,
          typeAPrice,
        });
      } else {
        missingItems[itemName] = true;
        pricesByDates[formattedDate].missing.push({
          itemName,
          selectedPrice,
          typeAPrice,
        });
      }
    });
    const missingItemsNames = Object.keys(missingItems);
    if (missingItemsNames.length > 0) {
      bulkImport.missingItems = missingItemsNames;
    }
    bulkImport.priceLists = Object.values(pricesByDates).sort((a, b) => {
      if (a.date.isBefore(b.date)) return -1;
      if (b.date.isBefore(a.date)) return 1;
      return 0;
    });
  }

  return {
    bulkImport,
    insertListFromFile,
    addMissingItems: missingItemsForm.submit,
    loadingMissingItems: missingItemsForm.submitting,
    submitImportedPriceLists: priceListsForm.submit,
    loadingImportedPriceLists: priceListsForm.submitting,
  };
}
