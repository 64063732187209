<template>
  <el-dialog
    :title="$t(title)"
    visible
    width="60%"
    top="0%"
    class="price-index-modal"
    custom-class="price-index-modal-content"
    :before-close="close"
  >
    <el-form>
      <FormInput v-model="data.name" label="name" />

      <el-tabs type="card">
        <el-tab-pane :label="$t('import template')">
          <FormInput v-model="data.importTemplate.itemNameHeader" label="item name header" />
          <FormInput v-model="data.importTemplate.selectedHeader" label="selected header" />
          <FormInput v-model="data.importTemplate.typeAHeader" label="type a header" />
          <FormInput v-model="data.importTemplate.dateHeader" label="date header" />
          <FormInput v-model="data.importTemplate.dateFormat" label="date format" />
        </el-tab-pane>
        <el-tab-pane ref="$itemsPane" :label="$t('items')">
          <FormInput v-for="item in data.items" :key="item._id" v-model="item.name" />
          <div class="items-bottom">
            <el-button native-type="button" type="text" icon="el-icon-circle-plus" @click="addItem">
              {{ $t('add') }}
            </el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <el-button-group slot="footer">
      <el-button type="primary" :loading="submitting" @click="submit">{{ $t('save') }}</el-button>
      <el-button @click="close">{{ $t('cancel') }}</el-button>
    </el-button-group>
  </el-dialog>
</template>

<script>
import { ref } from 'vue';
import { getRandomId } from '@/modules/core/utils/meteor-item-id';
import { useClonedModel } from '@/modules/core/compositions/prop-model';
import { update, insert } from '../compositions/price-indexes-operations';
import { useSubmitting } from '@/modules/core/compositions/submitting';
import FormInput from '@/modules/core/components/forms/FormInput';

export default {
  name: 'EditPriceIndexModal',
  components: { FormInput },
  props: {
    priceIndex: Object,
  },
  setup(props, { emit, root: { $nextTick } }) {
    const $itemsPane = ref(null);
    const data = useClonedModel(props.priceIndex, {
      importTemplate: {
        itemNameHeader: '',
        selectedHeader: '',
        typeAHeader: '',
        dateHeader: '',
        dateFormat: '',
      },
      items: [],
    });
    const isEditMode = !!props.priceIndex;
    const close = () => emit('close');
    return {
      title: isEditMode ? 'edit price index' : 'add price index',
      data,
      close,
      $itemsPane,
      addItem: async () => {
        data.items.push({
          _id: getRandomId(),
          name: '',
        });
        await $nextTick();
        const itemsPaneEl = $itemsPane.value.$el;
        itemsPaneEl.scrollTop = itemsPaneEl.scrollHeight;
      },
      ...useSubmitting(async () => {
        const obj = {
          name: data.name,
          items: data.items,
          importTemplate: data.importTemplate,
        };
        if (isEditMode) {
          await update({ _id: data._id, modifier: { $set: obj } });
        } else {
          await insert(obj);
        }
        close();
      }),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.price-index-modal {
  ::v-deep .price-index-modal-content {
    top: 10%;
    height: 80%;
    overflow: auto;
  }

  ::v-deep .el-dialog__body {
    padding: 10px;
  }

  ::v-deep .el-tab-pane {
    max-height: 300px;
    overflow: auto;
    scroll-behavior: smooth;
  }

  .items-bottom {
    background-color: rgba(256, 256, 256, 0.7);
    position: sticky;
    bottom: 0;
  }
}
</style>
